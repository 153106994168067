(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('RatingSummaryController', RatingSummaryController);

    RatingSummaryController.$inject = ['$scope', 'RatingSummaryService', 'Rating'];

    function RatingSummaryController($scope, RatingSummaryService, Rating) {
        var vm = this;


        Rating.getSummary(function(result){
            vm.feedbackratings = result;
          //  vm.allRatings = result;

      console.log("rrrrrrr" + result);
        });

    }
})();
