(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StartupSupport', StartupSupport);

    StartupSupport.$inject = ['$scope', 'StartupSupportService'];

    function StartupSupport($scope, StartupSupportService) {
        var vm = this;

    }
})();
