(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Projectlist', Projectlist);

    Projectlist.$inject = ['$resource'];

    function Projectlist($resource) {
        var resourceUrl = '/api/ProjectList/:id/:status';
        var userResourceUrl = '/api/ProjectListUser/:id/:status';

        return {
            projectList: $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: false },
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        return data ? angular.fromJson(data) : data;
                    }
                }
            }),
            projectListUser: $resource(userResourceUrl, {}, {
                'query': { method: 'GET', isArray: false, params: { page: '@page', size: '@size' } },
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        return data ? angular.fromJson(data) : data;
                    }
                }
            })
        };
    }
})();
