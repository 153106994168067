(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('DepartmentsBestPracticeController', DepartmentsBestPracticeController);

    DepartmentsBestPracticeController.$inject = ['$scope', 'DepartmentsBestPracticeService'];

    function DepartmentsBestPracticeController($scope, DepartmentsBestPracticeService) {
        var vm = this;

    }
})();
