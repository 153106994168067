(function() {
    'use strict';

    angular
        .module('investhryApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES'];

    function translationStorageProvider($cookies, $log, LANGUAGES) {
        return {
            get: get,
            put: put
        };

        function get(name) {
            var storedLang = $cookies.getObject(name);
            if (LANGUAGES.indexOf(storedLang) === -1) {
                console.log("Invalid language in storage:", storedLang, "Resetting to 'en'");
                $cookies.putObject(name, 'en');
                return 'en';
            }
            return storedLang;
        }

        function put(name, value) {
            console.log("Storing language preference:", value);
            $cookies.putObject(name, value);
        }
    }
})();
