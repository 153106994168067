(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('Rating', Rating);

    Rating.$inject = ['$resource', 'DateUtils'];

    function Rating ($resource, DateUtils) {
        var resourceUrl =  'api/ratings/:id';
        var resourceUrlSummary =  'api/ratingSummary';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastUpadte = DateUtils.convertDateTimeFromServer(data.lastUpadte);
                        data.appliedDate = DateUtils.convertDateTimeFromServer(data.appliedDate);
                        data.clearedDate = DateUtils.convertDateTimeFromServer(data.clearedDate);
                        data.actionTakenDate = DateUtils.convertDateTimeFromServer(data.actionTakenDate);
                    }
                    return data;
                }
            },
            'getSummary': {
                method: 'GET',
                url: resourceUrlSummary,
                isArray: true // Set to false if the response is an object instead of an array
            },
            'update': { method:'PUT' }
        });
    }
})();
