(function(){
    'use strict';

    angular
        .module('investhryApp')
        .controller('serviceLogController', serviceLogController);

    serviceLogController.$inject = ['$timeout', '$scope', '$state','$stateParams', '$uibModalInstance', 'serviceLogList','downloadService','service','DownloadExternalServiceFiles','projectService','Rating', 'Principal', 'Nmatoken', 'Applicationstatus', 'DepartmentService'];
    function serviceLogController ($timeout, $scope, $state ,$stateParams, $uibModalInstance, serviceLogList, downloadService,service,DownloadExternalServiceFiles,projectService,Rating, Principal, Nmatoken, Applicationstatus, DepartmentService) {
        var vm = this;
        vm.clear = clear;
        vm.downloadFile=downloadFile;
        vm.ServiceLog = serviceLogList;
        vm.projectService=projectService;
        vm.service=service;
        vm.pdfconverter= pdfconverter;
        vm.downFile= downFile;
        vm.saveRating= saveRating;
        vm.rating = {
            isFeeManuallySubmit: '',
            isDocPhysicallySubmit: '',
            isApprovalOnTime: '',
            isCertificateDownload: '',
            isPhysicallyVisit: ''
        };
        vm.rating.rating ="Excellent";
        vm.userFile= userFile;
        vm.rating.serviceId=$stateParams.serviceid;
        vm.rating.projectId=$stateParams.projectid;
        vm.rating.id= $stateParams.projectserviceid;


        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            })
        }

        function pdfconverter () {
            var element = document.getElementById('pdf_data');
            html2pdf(element,{
                  margin:  0.5,
                  html2canvas: { dpi:192, letterRendering: true },
                  jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            });
        }

        if (vm.projectService.serviceid==='bdb451cb-f12b-4352-8360-e6e3a715386f'){
            vm.nmatoken=Nmatoken.get({id:vm.projectService.id},function () {
                vm.tokenFound=true;
            });
            vm.applicationstatus=Applicationstatus.get({id:vm.projectService.id},function () {
                vm.nocFound=true;
            });
        }

        $scope.isDateExceed=function()
        {
         if(vm.projectService.isDateExceeded===true){
            return true;
         }
         else{
            return false;
         }
        };

        var isdateexceeded = $scope.isDateExceed();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.isServiceInList=function() {
           return vm.projectService.isIntegrated === true;
        };

        function downFile(servicelog) {
            vm.reApplyCounter = servicelog.reApplyCounter;
            vm.dwnFile = servicelog.fileName;
            vm.act = servicelog.actionTaken;
            downloadFile();
        }

        function userFile(servicelog){
            vm.reApplyCounter = servicelog.reApplyCounter;
            vm.dwnFile = servicelog.fileName;
            vm.act = servicelog.actionTaken;
            if(vm.act === "Cleared"){

                Rating.get({id: $stateParams.projectserviceid}, onGetSuccess, onGetError)
            }
            else {
                downloadFile();
            }
        }

        function onGetSuccess(){
            downloadFile();
        }

        function onGetError(){
            $("#ratings").modal('show');
        }

        function saveRating() {
            vm.isSaving = true;
            vm.rating.serviceId=$stateParams.serviceid;
            vm.rating.projectId=$stateParams.projectid;
            vm.rating.id= $stateParams.projectserviceid;
           Rating.update(vm.rating);
          downloadFile();
          $("#ratings").modal('hide');
        }

        function downloadFile()
        {
            if(($scope.isServiceInList()) && (vm.act!=='OpenComment'))
            {
                if(isdateexceeded){
                    downloadExt();
                }
                else {
                    if(vm.reApplyCounter > 1){
                        downloadExt();
                    }
                    else {
                        downloadHEPC();
                    }
                }
            }
            else
            {
                downloadHEPC();
            }
        }

        function downloadHEPC() {
            downloadService.download(vm.dwnFile)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
            });
        }

        function downloadExt() {
            DepartmentService.get({id: $stateParams.serviceid}, function (result) {
                if(result.downloadUrl == null){
                    alert("Download URL not found! Kindly contact concerned department.");
                    return;
                }
                DownloadExternalServiceFiles.download($stateParams.projectid, $stateParams.serviceid, vm.reApplyCounter, result.downloadUrl);
            });
        }
    }
})();


