(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StartupPoliciesLoginController', StartupPoliciesLoginController);

    StartupPoliciesLoginController.$inject = ['$stateParams', '$scope', '$state', '$translate', 'IncentiveForm', 'Principal', 'LoginService', '$timeout', '$location'];

    function StartupPoliciesLoginController($stateParams, $scope, $state, $translate, IncentiveForm, Principal, LoginService, $timeout, $location) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;

        // Manually refresh translations after page load
        $translate.refresh().then(function() {
            console.log("Translations refreshed in controller");
        });

        IncentiveForm.query(function(result) {
            vm.userdetails = result;
        });

        vm.login = function() {
            LoginService.open();
        };
    }
})();
