(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('ShowCafsStateNodalOfficerService', ShowCafsStateNodalOfficerService);

    ShowCafsStateNodalOfficerService.$inject = ['$resource'];

    function ShowCafsStateNodalOfficerService ($resource) {
        var resourceUrl = 'api/getDeativatedCafs';
        var rejectUrl = 'api/rejectDeativatedCafs/:id';

        return {
            getDeactivatedCafs: $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true },
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': { method: 'PUT' }
            }),
            rejectDeactivatedCaf: $resource(rejectUrl, { id: '@id' }, {
                'reject': {
                    method: 'GET',
                    transformResponse: function (data) {
                        // if (data) {
                        //     data = angular.fromJson(data);
                        // }
                        return data;
                    }
                }
            })  // New service method
        };
    }
})();
