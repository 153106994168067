(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ShowCafsStateNodalOfficer', ShowCafsStateNodalOfficer);

    ShowCafsStateNodalOfficer.$inject = ['$scope', '$stateParams', 'TowerDcProjects', 'Department', '$filter', 'TowerserviceNO', 'Municipals', 'ShowCafsStateNodalOfficerService'];

    function ShowCafsStateNodalOfficer($scope, $stateParams, TowerDcProjects, Department, $filter, TowerserviceNO, Municipals, ShowCafsStateNodalOfficerService) {
        var vm = this;
        vm.projects = [];
        vm.status='Pending';
        vm.currentDepartment="All";
        vm.municipals = [];

        $scope.required={
            department: {},
            municipal:{}
        };
        $scope.requiredMunicipal = [];
        vm.towerserviceNO = {};

        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";


        loadAll();

        function loadAll() {
            ShowCafsStateNodalOfficerService.getDeactivatedCafs.query(function (result) {
                vm.projects = result;
            });
        }


        vm.rejectCaf = function (cafId) {
            ShowCafsStateNodalOfficerService.rejectDeactivatedCaf.reject({ id: cafId }, function (response) {
                console.log("Application CAF rejected successfully:", response);
                alert("Applications related to this particular CAF rejected successfully");
               // loadAll(); // Reload the list after rejection
            }, function (response) {
               // console.error("Error rejecting CAF:", error);
                alert(response.message);
            });
        };




        vm.updateTowerServiceNO= function (id) {
            vm.towerserviceNO.projectdetailId = id;
            vm.towerserviceNO.district = vm.tower_service.district;
            vm.towerserviceNO.officerList = $scope.required.department;
            if($scope.requiredMunicipal.length !== 0) {
                for (var i = 0; i < $scope.requiredMunicipal.length; i++ ) {
                    if(vm.towerserviceNO.subname) {
                        vm.towerserviceNO.subname = vm.towerserviceNO.subname + "," + $scope.requiredMunicipal[i];
                    } else {
                        vm.towerserviceNO.subname = $scope.requiredMunicipal[i];
                    }
                }
            }
            // Projectservicedetail.get({id: vm.towerserviceNO.projectdetailId},function (data) {
            //     vm.projectServiceLog.projectid = $stateParams.projectid;
            //     vm.projectServiceLog.serviceid = data.serviceid;
            //     vm.projectServiceLog.comments = vm.towerserviceNO.subname;
            //     vm.projectServiceLog.actionTaken = vm.towerserviceNO.subname;
            //     Projectlogupdated.save(vm.projectServiceLog, function (data) {
            //         console.log(data);
            //     }, function (error) {
            //         console.log(error);
            //     });
            // });
            TowerserviceNO.save({id: '1'}, vm.towerserviceNO);
            vm.requiredMunicipal= [];
            location.reload();
            vm.towerserviceNO = {};
        };




    }
})();
