(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ThirdPartyInspectionController', ThirdPartyInspectionController);

    ThirdPartyInspectionController.$inject = ['$scope', 'ThirdPartyInspectionService'];

    function ThirdPartyInspectionController($scope, ThirdPartyInspectionService) {
        var vm = this;

    }
})();
