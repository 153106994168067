(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('listprojectController', listprojectController);

    listprojectController.$inject = ['$scope','$state', 'Projectlist', 'Project', 'Survey', 'Sector', 'DistrictVillageBlockMapping', 'Principal', '$filter'];

    function listprojectController($scope, $state, Projectlist , Project, Survey, Sector, DistrictVillageBlockMapping, Principal, $filter) {
        var vm = this;
        vm.accout = null;
        vm.project=Project;
        vm.onBack = onBack;
        vm.hideApp=hideApp;
        vm.project = {};
        vm.survey = {};
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.currentProject = currentProject;
        vm.total_ground_investment = null;
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        vm.selectedValue = "10";

        vm.datePickerOpenStatus.prodctionStartDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        loadAll();

        function loadAll() {
            Principal.identity().then(function(account) {
                vm.account = account;

                if (account.authorities.includes("ROLE_ADMIN") || account.authorities.includes("ROLE_SUB_ADMIN")) {
                    return;
                }

                if (account.authorities.includes("ROLE_USER")) {
                    if (!Projectlist.projectListUser || !Projectlist.projectListUser.query) {
                        console.error("Projectlist.projectListUser.query is undefined!");
                        return;
                    }

                    Projectlist.projectListUser.query({ page: $scope.currentPage - 1, size: $scope.pageSize }, function(result) {
                        processProjectList(result);
                    });
                } else {
                    if (!Projectlist.projectList || !Projectlist.projectList.query) {
                        console.error("Projectlist.projectList.query is undefined!");
                        return;
                    }

                    Projectlist.projectList.query({ id: "1", status: "All" }, function(result) {
                        processProjectList(result);
                    });
                }
            });
        }

        function processProjectList(result) {
            vm.totalElements = result.totalElements;
            if (result.content) {
                for (var i = 0; i < result.content.length; i++) {
                    if (result.content[i].caftype === 'FILMCAF') {
                        result.content[i].caftype = 'Film';
                    } else if (result.content[i].caftype === 'LandExchange') {
                        result.content[i].caftype = 'Land';
                    } else if (result.content[i].caftype === 'Renewable') {
                        result.content[i].caftype = 'Renew';
                    }
                }

                vm.projectlistNA = $filter('filter')(result.content, { isdraft: true });
                vm.projectlistFinal = $filter('filter')(result.content, { isdraft: false });
            } else {
                console.error("Result content is undefined!");
            }
        }



        vm.surveyFormOptions = function (projectObj) {
            if(projectObj.caftype === 'New' && projectObj.applicationDate < "2018-09-18"){
                Survey.get({id: projectObj.projectid},OnSurveyFindSuccess, OnSurveyFindError);
                currentProject(projectObj);
            }
            else {
                $state.go('projectdetailjist',{projectid:projectObj.projectid});
            }
        };

        function OnSurveyFindSuccess(result) {
            if(result.investmentDate !== null){
                $state.go('projectdetailjist',{projectid:result.id})
            }
            else {
                UIkit.modal('#Next-modal').show();
            }
        }

        function OnSurveyFindError() {
            UIkit.modal('#details-modal').show();
        }

        function currentProject(projectObj){
            vm.survey = {};
            Project.get({id: projectObj.projectid},function(result){
                vm.project = result;
                vm.project.prodctionStartDate = new Date(result.prodctionStartDate);

                DistrictVillageBlockMapping.query({id: '1',districtName: result.district},function(result) {
                    vm.villages = result;
                    vm.searchQuery = null;
                });
                vm.foreigninvestment_in_crores = calculateforeignInvestmentInCrore(vm.project);
                vm.foreigninvestment_in_lacs = calculateforeignInvestmentInlacs(vm.project);
                vm.foreigninvestment_in_thousands = calculateforeignInvestmentInThousands(vm.project);
                showForeign_investment(vm.project);
            });
            Sector.query(function(result) {
                vm.sectors = result;
                vm.searchQuery = null;
            });
        }

        function showForeign_investment(project) {
            if(project.foreign_investment !== null && project.foreign_investment !== 0){
                vm.radioToggle = 'Yes';
                vm.showFDI = true;
            }
            else if(project.foreign_investment === null || project.foreign_investment === 0){
                vm.radioToggle = 'No';
                vm.showFDI = false;
            }
        }

        function calculateforeignInvestmentInCrore (project) {
            if((project.foreign_investment !== 0) && (project.foreign_investment !== null)){
                return parseInt(project.foreign_investment.toString().split('.')[0]);
            }
        }

        function calculateforeignInvestmentInlacs (project) {
            if((project.foreign_investment !== 0) && (project.foreign_investment !== null)){
                if (project.foreign_investment.toString().indexOf('.') !== -1) {
                    return parseInt(project.foreign_investment.toString().split('.')[1].substring(0, 2));
                }
                return 0;
            }
        }

        function calculateforeignInvestmentInThousands (project) {
            if((project.foreign_investment !== 0) && (project.foreign_investment !== null)){
                if (project.foreign_investment.toString().indexOf('.') !== -1) {
                    return parseInt(project.foreign_investment.toString().split('.')[1].substring(2, 4));
                }
                return 0;
            }
        }

        vm.setBlockCategory = function (villageName, villageList) {
            vm.villageNames =[];
            angular.forEach(villageList, function(value, key){
                vm.villageNames.push(value.village);
            });
            if(vm.villageNames.indexOf(villageName) !== -1){
                vm.invalidVillage = false;
                vm.project.blockCategory = villageList[vm.villageNames.indexOf(villageName)].category;
            }
            else {
                vm.invalidVillage = true;
            }
        };

        vm.changeInForeignInvestment = function(){
            var projectCostInCrore = vm.foreigninvestment_in_crores || '00';
            var projectCostInLacs = vm.foreigninvestment_in_lacs || '00' ;
            var projectCostInThousands = vm.foreigninvestment_in_thousands ||'00';
            if(vm.foreigninvestment_in_lacs<10){
                projectCostInLacs = '0'+vm.foreigninvestment_in_lacs;
            }
            if(vm.foreigninvestment_in_thousands<10){
                projectCostInThousands = '0'+vm.foreigninvestment_in_thousands;
            }
            return vm.total_foreign_investment = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
        };

        vm.changeInGroundInvestment =function () {
            var projectCostInCrore = vm.ground_investment_in_crore || '00';
            var projectCostInLacs = vm.ground_investment_in_lacs || '00' ;
            var projectCostInThousands = vm.ground_investment_in_thousands ||'00';
            if(vm.ground_investment_in_lacs<10){
                projectCostInLacs = '0'+ vm.ground_investment_in_lacs;
            }
            if(vm.ground_investment_in_thousands<10){
                projectCostInThousands = '0'+ vm.ground_investment_in_thousands;
            }
            return vm.total_ground_investment = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
        };

        vm.save = function (){
            vm.project.foreign_investment = vm.total_foreign_investment;
            Project.update(vm.project, onSaveSuccess, OnSaveError);
        };

        function onSaveSuccess(result){
            UIkit.modal('#Next-modal').show();
            UIkit.modal('#details-modal').hide();
            vm.survey.id = result.id;
            Survey.update(vm.survey);
            vm.isSaving = false;
        }

        function onBack(){
            UIkit.modal('#Next-modal').hide();
            UIkit.modal('#details-modal').show();
        }

        function hideApp(){
            UIkit.modal('#details-modal').hide();
            UIkit.modal('#Next-modal').hide();
        }

        vm.saveSurvey = function(){
            vm.survey.id = vm.project.id;
            vm.survey.groundedInvestment = vm.total_ground_investment;
            Survey.update(vm.survey,onSaveSurveySuccess,OnSaveError);
        };

        function onSaveSurveySuccess(result) {
            UIkit.modal('#Next-modal').hide();
            UIkit.modal('#details-modal').hide();
            $scope.$emit('investhryApp:SurveyUpdate', result);
            vm.isSaving = false;
        }

        function OnSaveError(){
            vm.isSaving = false;
        }

        $scope.setPageSize = function (pageSizeVal) {
            $scope.pageSize = pageSizeVal;
            loadAll();
        };

        $scope.pageChangedHandler = function(num) {
            loadAll();
        };

    }
})();



