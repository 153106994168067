(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('deativatedCafs', {
            parent: 'app',
            url: '/deativatedCafs',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_STATE_NODAL_OFFICER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/showcafs-stateNodalOfficer/showcafs-state-nodal-officer.html',
                    controller: 'ShowCafsStateNodalOfficer',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('state');
                    return $translate.refresh();
                }]
            }
        })

    }
})();
