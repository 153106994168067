(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AddCAFnewController', AddCAFnewController);

    AddCAFnewController.$inject = ['$timeout', '$scope', 'Principal', 'LoginService', '$state', 'AddCAF', 'project','District', 'Landusezoneclassification', 'Businessentitys', 'Sector', 'Industrysize', 'Projectype', 'Country', 'Connectingroad', 'Block', 'City_town_village', 'Approvalforms', 'cAFAttachmentPhotoobject', 'cAFAttachmentSignobject', 'FileManagement', 'CAFAttachment', 'cafAttachments','ExtendedUserInformation','CountryService','$filter', 'DistrictVillageBlockMapping','CheckContent','AlertService','Estate', 'State','GetImageForApplicant','CAFOtp'];

    function AddCAFnewController ($timeout, $scope, Principal, LoginService, $state, AddCAF, project, District, Landusezoneclassification, Businessentitys, Sector, Industrysize, Projectype, Country, Connectingroad, Block, City_town_village, Approvalforms, cAFAttachmentPhotoobject, cAFAttachmentSignobject, FileManagement, CAFAttachment, cafAttachments,ExtendedUserInformation,CountryService,$filter, DistrictVillageBlockMapping,CheckContent,AlertService, Estate, State ,GetImageForApplicant,CAFOtp) {
        var vm = this;

        vm.proj = project;
        vm.cAFAttachmentSignobject = cAFAttachmentSignobject;
        vm.cAFAttachmentPhotoobject = cAFAttachmentPhotoobject;
        vm.cAFAttachmentSign = "";
        vm.cAFAttachmentPhoto = "";
        $scope.file = {};
        vm.cafAttachments = cafAttachments;
        vm.investorphoto = null;
        vm.investorsign = null;
        vm.showEstateConfirmation = false;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.errorMessage=errorMessage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.sectorValueChange = sectorValueChange;
        vm.showIndustrialEstates = false;
        vm.estates = [];

        vm.OTP = false;
        vm.OTPstatus = false;
        vm.cafOTP= null;

        vm.datePickerOpenStatus.prodctionStartDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        loadAll();
        for(var cafAttachmentscounter=0;cafAttachmentscounter<vm.cafAttachments.length;cafAttachmentscounter++)
        {
            if(vm.cafAttachments[cafAttachmentscounter].docName=="Applicant Photo")
            {
                var filename=vm.cafAttachments[cafAttachmentscounter].fileName;
                vm.investorphoto1=GetImageForApplicant.getImageApplicant(filename);
                vm.cafAttachementInvestorPhoto=vm.cafAttachments[cafAttachmentscounter];

            }
            if(vm.cafAttachments[cafAttachmentscounter].docName=="Signature")
            {
                var filename=vm.cafAttachments[cafAttachmentscounter].fileName;
                vm.investorsign1=GetImageForApplicant.getImageApplicant(filename);
            }
        }


        function loadAll() {
            vm.sectorValueChange (project.sectorname);

            if(vm.isIndustrialEstate!="" && vm.proj.district!=null){
                vm.showEstateConfirmation = true;
                vm.showIndustrialEstates =true;
                if(vm.proj.industrialEstate!= null){
                    vm.isIndustrialEstate ="yes";
                    Estate.query(function (estates) {
                       vm.estates = $filter('filter')(estates, {district: vm.proj.district});
                    });
                }
            }

            if(project.prodctionStartDate !== null){
                vm.proj.prodctionStartDate = new Date(project.prodctionStartDate);
            }
            if(project.investmentType === 'Other'){
                vm.showOtherInvestmentType = true;
            }
            else if(project.investmentType === null){
                project.otherInvestmetType = ' ';
            }

            if(project.foreign_investment !== null && project.foreign_investment !== 0){
                vm.radioToggle = 'Yes';
                vm.showFDI = true;
            }
            else if(project.foreign_investment === null || project.foreign_investment === 0){
                vm.radioToggle = 'No';
                vm.showFDI = false;
            }
        }

        vm.getIndustrialEstates = function(){
                   if (vm.isIndustrialEstate === 'yes'){
                       vm.showIndustrialEstates = true;
                       Estate.query(function (estates) {
                           vm.estates = $filter('filter')(estates, {district: vm.proj.district});
                       });
                   }else {
                       vm.showIndustrialEstates = false;
                       return;
                   }
         };

        vm.getVillages = function (districtName) {
            vm.showEstateConfirmation =true;
            vm.isIndustrialEstate = "no";
            vm.showIndustrialEstates = false;
            DistrictVillageBlockMapping.query({id: '1',districtName: districtName},function(result) {
                vm.villages = result;
                vm.searchQuery = null;
                vm.proj.village = null;
                vm.proj.blockCategory = null;
            });
        };

        vm.setBlockCategory = function (villageName, villageList) {
            vm.showEstateConfirmation = true;
            vm.villageNames =[];
            angular.forEach(villageList, function(value, key){
                vm.villageNames.push(value.village);
            });
            if(vm.villageNames.indexOf(villageName) !== -1){
                vm.invalidVillage = false;
                vm.proj.blockCategory = villageList[vm.villageNames.indexOf(villageName)].category;
            }
            else {
                vm.invalidVillage = true;
            }
        };

        function sectorValueChange (sectorName) {
            if(sectorName === 'Other Industries'){
                if(project.otherFDI === null || vm.proj.otherFDI === 'NA' ){
                    vm.proj.otherFDI = '';
                }
                vm.showOtherSector = true;
            }
            else {
                vm.showOtherSector = false;
                vm.proj.otherFDI = 'NA'
            }
        };

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        $scope.enterEvent= function (event) {
            event.preventDefault();
            vm.ShowError=true;
        };

        function errorMessage() {
            vm.ShowError=false;
        }
        vm.blockopen = function(){
            $('#blockCategoryHint').modal('show');
        };

        vm.showOtherInvestment = function (currentValue) {
            vm.total_project_cost=0;
            vm.proj.total_project_cost = 0;
            vm.proj.totalproposedprojectarea = 0;
            vm.proj.haryanadomicile = 0;
            vm.proj.totalpurposedemployment = 0;
            vm.proj.nonharyanadomicile = 0;
            vm.project_cost_in_crore = 0;
            vm.project_cost_in_lacs = 0;
            vm.project_cost_in_thousands = 0;
            vm.project_area_in_acres = 0;
            vm.project_area_in_kanal = 0;
            vm.project_area_in_marla = 0;
            vm.proj.powerRequirement = null;
            vm.showOtherInvestmentType = currentValue === 'Other';

        };

        vm.proposedEmployment = function(domicile){
            if(domicile === 'haryana'){
                vm.proj.nonharyanadomicile = vm.proj.totalpurposedemployment - vm.proj.haryanadomicile;
                if(vm.proj.nonharyanadomicile < 0){
                    vm.proj.haryanadomicile = 0;
                    vm.proj.nonharyanadomicile = 0;
                    alert("Your Value Is Not Valid");
                }
            }else{
                vm.proj.haryanadomicile = vm.proj.totalpurposedemployment - vm.proj.nonharyanadomicile;
                if(vm.proj.haryanadomicile < 0){
                    vm.proj.nonharyanadomicile = 0;
                    vm.proj.haryanadomicile = 0;
                    alert("Your Value Is Not Valid");
                }
            }
        };

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if(vm.proj.id==null){
                vm.proj.firstname = account.firstName;
                vm.proj.lastname = account.lastName;
                vm.proj.emailprimary = account.email;
                    ExtendedUserInformation.get({id:account.id},function (result) {
                        vm.proj.address1=result.userAddress;
                        vm.proj.address2='';
                        vm.proj.address3='';
                        vm.proj.mobilenumber = result.userMobile;

                    });
                }
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

        vm.districts = [];
        loadAllDistrict();
        function loadAllDistrict(){
            District.query(function(result) {
               vm.districtstemp = result;
               angular.forEach(vm.districtstemp, function(value, key){
                    if((value.districtname!=='Narnaul' && value.districtname!== 'Chandigarh HQ')){
                        vm.districts.push(value);
                    }
               });
               vm.searchQuery = null;
           });
        }
        Landusezoneclassification.query(function(result) {
           vm.landusezoneclassifications = result;
           vm.searchQuery = null;
        });
        Businessentitys.query(function(result) {
           vm.businessentities = result;
           vm.searchQuery = null;
        });
        Sector.query(function(result) {
           vm.sectors = result;
           vm.searchQuery = null;
        });
        Industrysize.query(function(result) {
           vm.industrysizes = result;
           vm.searchQuery = null;
        });
        Projectype.query(function(result) {
           vm.projectypes = result;
           vm.searchQuery = null;
        });
        Country.query(function(result) {
           vm.countries = result;
           vm.searchQuery = null;
        });
        State.query(function(result) {
           vm.states = result;
           vm.searchQuery = null;
        });
        Block.query(function(result) {
           vm.blocks = result;
           vm.searchQuery = null;
        });
        Connectingroad.query(function(result) {
           vm.connectingroads = result;
           vm.searchQuery = null;
        });
        City_town_village.query(function(result) {
           vm.city_town_villages = result;
           vm.searchQuery = null;
        });
        Approvalforms.query(function(result) {
           vm.approvalforms = result;
           vm.searchQuery = null;
        });

    vm.project_area_in_acres=Math.floor(calculateAreaInAcre());
    vm.project_area_in_kanal= Math.floor(calculateAreaInKanal());
    vm.project_area_in_marla=Math.floor(calculateAreaInMarla());

    var projectAreaInAcres=vm.project_area_in_acres || 0;
    var projectAreaInKanal=vm.project_area_in_kanal || 0 ;
    var projectAreaInMarla=vm.project_area_in_marla || 0;
    vm.totalproposedprojectarea=projectAreaInAcres+(projectAreaInKanal/8)+(projectAreaInMarla/160);

    vm.changeInArea=function(){
        var projectAreaInAcres=vm.project_area_in_acres || 0;
        var projectAreaInKanal=vm.project_area_in_kanal || 0 ;
        var projectAreaInMarla=vm.project_area_in_marla || 0;
        vm.totalproposedprojectarea=projectAreaInAcres+(projectAreaInKanal/8)+(projectAreaInMarla/160);
    }

    vm.changeInCost=function(){
          var projectCostInCrore=vm.project_cost_in_crore || '00';
          var projectCostInLacs=vm.project_cost_in_lacs|| '00' ;
          var projectCostInThousands=vm.project_cost_in_thousands||'00';
          if(vm.project_cost_in_lacs<10){
             projectCostInLacs='0'+vm.project_cost_in_lacs;
          }
          if(vm.project_cost_in_thousands<10){
             projectCostInThousands='0'+vm.project_cost_in_thousands;
          }
          return vm.total_project_cost = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
    };


    vm.changeInForeignInvestment=function(){
        var projectCostInCrore=vm.foreigninvestment_in_crores || '00';
        var projectCostInLacs=vm.foreigninvestment_in_lacs|| '00' ;
        var projectCostInThousands=vm.foreigninvestment_in_thousands||'00';
        if(vm.foreigninvestment_in_lacs<10){
            projectCostInLacs='0'+vm.foreigninvestment_in_lacs;
        }
        if(vm.foreigninvestment_in_thousands<10){
            projectCostInThousands='0'+vm.foreigninvestment_in_thousands;
        }
        return vm.total_foreign_investment = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
    };


    vm.changeInNri_investment=function(){
        var projectCostInCrore=vm.nri_investment_in_crore || '00';
        var projectCostInLacs=vm.nri_investment_in_lacs|| '00' ;
        var projectCostInThousands=vm.nri_investment_in_thousands||'00';
        if(vm.nri_investment_in_lacs<10){
            projectCostInLacs='0'+vm.nri_investment_in_lacs;
        }
        if(vm.nri_investment_in_thousands<10){
            projectCostInThousands='0'+vm.nri_investment_in_thousands;
        }
        return vm.total_nri_investment = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;
    };

    var calculateCostInCrore=function(){
       return parseInt(vm.proj.total_project_cost.toString().split('.')[0]);
    };

    var calculateCostInLacs=function(){
       if(vm.proj.total_project_cost.toString().indexOf('.')!=-1){
        var org = vm.proj.total_project_cost*10000000;
        var lac = (org/100000)%100;
        return parseInt(lac);
       }
       return 0;
    };

    var calculateCostInThousands=function(){
       if(vm.proj.total_project_cost.toString().indexOf('.')!=-1){
       var org = vm.proj.total_project_cost*10000000;
       var th = (org/1000)%100;
        return parseInt(th);
       }
       return 0;
    };

        var calculateforeignInvestmentInCrore = function () {
         if((vm.proj.foreign_investment!=0) && (vm.proj.foreign_investment!=null)){
            return parseInt(vm.proj.foreign_investment.toString().split('.')[0]);
            }
       };

        var calculateforeignInvestmentInlacs = function () {
         if((vm.proj.foreign_investment!=0) && (vm.proj.foreign_investment!=null)){
            if (vm.proj.foreign_investment.toString().indexOf('.') != -1) {
              return parseInt(vm.proj.foreign_investment.toString().split('.')[1].substring(0, 2));
                }
         return 0;
         }
        };

        var calculateforeignInvestmentInThousands = function () {
         if((vm.proj.foreign_investment!=0) && (vm.proj.foreign_investment!=null)){
            if (vm.proj.foreign_investment.toString().indexOf('.') != -1) {
                  return parseInt(vm.proj.foreign_investment.toString().split('.')[1].substring(2, 4));
            }
            return 0;
            }
        };


        var calculatenri_investmentInCrore = function () {
        if((vm.proj.nri_investment!=0) && (vm.proj.nri_investment!=null)){
            return parseInt(vm.proj.nri_investment.toString().split('.')[0]);
            }
        };


        var calculatenri_investmentInlacs = function () {
        if((vm.proj.nri_investment!=0) && (vm.proj.nri_investment!=null)){
            if (vm.proj.nri_investment.toString().indexOf('.') != -1) {
                return parseInt(vm.proj.nri_investment.toString().split('.')[1].substring(0, 2));
            }
            return 0;
            }
        };

        var calculatenri_investmentInThousands = function () {
        if((vm.proj.nri_investment!=0) && (vm.proj.nri_investment!=null)){
            if (vm.proj.nri_investment.toString().indexOf('.') != -1) {
                return parseInt(vm.proj.nri_investment.toString().split('.')[1].substring(2, 4));
            }
            return 0;
          }
        };

    function calculateAreaInAcre(){
       return vm.proj.totalproposedprojectarea;
    }

    function calculateAreaInKanal(){
       var areainkanal=vm.proj.totalproposedprojectarea-Math.floor(vm.proj.totalproposedprojectarea);
       var areaInKanal=areainkanal*8;
       return areaInKanal;
    }

    function calculateAreaInMarla(){
       var areainmarla=Math.floor(calculateAreaInKanal());
       var areaInMarla=(calculateAreaInKanal()-areainmarla)*20;
       return Math.round(areaInMarla);
    }


    vm.project_cost_in_crore=calculateCostInCrore();
    vm.project_cost_in_lacs= calculateCostInLacs();
    vm.project_cost_in_thousands=calculateCostInThousands();

        vm.foreigninvestment_in_crores=calculateforeignInvestmentInCrore();
        vm.foreigninvestment_in_lacs=calculateforeignInvestmentInlacs();
        vm.foreigninvestment_in_thousands=calculateforeignInvestmentInThousands();


        vm.nri_investment_in_crore=calculatenri_investmentInCrore();
        vm.nri_investment_in_lacs=calculatenri_investmentInlacs();
        vm.nri_investment_in_thousands=calculatenri_investmentInThousands();


      var projectCostInCrore=vm.project_cost_in_crore || '00';
      var projectCostInLacs=vm.project_cost_in_lacs|| '00' ;
      var projectCostInThousands=vm.project_cost_in_thousands||'00';
      if(vm.project_cost_in_lacs<10){
         projectCostInLacs='0'+vm.project_cost_in_lacs;
      }
      if(vm.project_cost_in_thousands<10){
         projectCostInThousands='0'+vm.project_cost_in_thousands;
      }


    vm.total_project_cost = projectCostInCrore+"."+projectCostInLacs+""+projectCostInThousands ;


        var foreignInvestmentInCrore=vm.foreigninvestment_in_crores || '00';
        var foreignInvestmentLacs=vm.foreigninvestment_in_lacs|| '00' ;
        var foreignInvestmentThousands=vm.foreigninvestment_in_thousands||'00';
        if(vm.foreigninvestment_in_lacs<10){
            foreignInvestmentLacs='0'+vm.foreigninvestment_in_lacs;
        }
        if(vm.foreigninvestment_in_thousands<10){
            foreignInvestmentThousands='0'+vm.foreigninvestment_in_thousands;
        }
        vm.total_foreign_investment = foreignInvestmentInCrore+"."+foreignInvestmentLacs+""+foreignInvestmentThousands ;

        var nriInCrore=vm.nri_investment_in_crore || '00';
        var nriInLacs=vm.nri_investment_in_lacs|| '00' ;
        var nriInThousands=vm.nri_investment_in_thousands||'00';
        if(vm.nri_investment_in_lacs<10){
            nriInLacs='0'+vm.nri_investment_in_lacs;
        }
        if(vm.nri_investment_in_thousands<10){
            nriInThousands='0'+vm.nri_investment_in_thousands;
        }
        vm.total_nri_investment = nriInCrore+"."+nriInLacs+""+nriInThousands;


    vm.saveProject = saveProject;

    function saveProject(flag){
        vm.isSaving = true;
       if(vm.proj.totalpurposedemployment > 0 && (vm.proj.haryanadomicile + vm.proj.nonharyanadomicile != vm.proj.totalpurposedemployment)){
            alert("Total of employment with Haryana Domicile and Non-Haryana Domicile must be equal to Total Proposed Employment!!");
            vm.proj.haryanadomicile = 0;
            vm.proj.nonharyanadomicile = 0;
            return;
       }
       if(vm.proj.totalpurposedemployment === 0){
            vm.proj.haryanadomicile = 0;
            vm.proj.nonharyanadomicile = 0;
       }
       vm.proj.totalproposedprojectarea = vm.totalproposedprojectarea;
       vm.proj.total_project_cost = vm.total_project_cost;
       vm.proj.foreign_investment=vm.total_foreign_investment;
       vm.proj.nri_investment=vm.total_nri_investment;

        if(vm.proj.investmentType ==="Existing Project (not a new investment in state)"){
            vm.proj.powerRequirement = null;
        }


       if(flag=='Draft'){
         vm.proj.isdraft=true;
       }
       else if(flag=='Final'){
         vm.proj.isdraft=false;
       }
        if((angular.isDefined(vm.cAFAttachmentSign.investorsign)) || (angular.isDefined(vm.cAFAttachmentPhoto.investorphoto))) {
            var extFile = "jpg";
            var extPic = "jpg";
            var sign = true;
            var photo = true;
            if (angular.isDefined(vm.cAFAttachmentSign.investorsign)) {
                var sign = vm.cAFAttachmentSign.investorsign;
                var fileNameArray = sign.name.split(".");
                if (fileNameArray.length < 3) {
                    if (fileNameArray[0] != "") {
                        var signDot = sign.name.lastIndexOf(".") + 1;
                        extFile = sign.name.substr(signDot, sign.length).toLowerCase();
                    }
                    else {
                        alert("Invalid File Name");
                        var sign = false;
                    }
                }
                else {
                    alert('Signature File should be in appropriate format')
                    var sign = false;

                }
            }
            if (angular.isDefined(vm.cAFAttachmentPhoto.investorphoto)) {
                var investorPhoto = vm.cAFAttachmentPhoto.investorphoto;
                var filePhotoArray = investorPhoto.name.split(".");

                if (filePhotoArray.length < 3) {
                    if (filePhotoArray[0] != "") {
                        var picDot = investorPhoto.name.lastIndexOf(".") + 1;
                        extPic = investorPhoto.name.substr(picDot, investorPhoto.length).toLowerCase();
                    } else {
                        alert("Invalid File Name");
                        var photo = false;
                    }
                }
                else {
                    alert('Applicant Photo File should be in appropriate format');
                    var photo = false;
                }
            }
            if(photo && sign) {
                if ((extFile == "jpg" || extFile == "jpeg" || extFile == "png") && (extPic == "jpg" || extPic == "jpeg" || extPic == "png")) {
                    saveCAF();
                }
                else {
                    alert("Only jpg/jpeg and png files are allowed!");
                }
            }
        }
        else {
            saveCAF();
        }
    }


    function onSaveProjectSuccess(result)
    {
       uploadphoto(result.id);
       uploadsign(result.id);
       vm.isSaving = false;

       if(vm.proj.isdraft === true){
            alert('Please note that any draft CAF saved in the system for more than 10 days shall be automatically deleted. Hence, you are requested to submit the CAF along with the required information within the prescribed time limit.\n' +
                'Kindly contact our helpdesk at 1800-180-2132 in case you need any assistance in filling out the CAF Form.');
        }
        else{
            alert('CAF Saved');
        }
        $state.go('listproject');
    }
    function onSaveProjectError(result){
        if(result.status === 409){
            vm.existingCAFPin = result.data.cafpin;
            vm.existingCAFLogin = result.data.userlogin;
            alert(' It has been observed that the details entered by you are already present in our system with the following details: CAF Number: ' + vm.existingCAFPin + '  Login ID: ' + vm.existingCAFLogin + '  Please login using the above mentioned login ID to proceed further. In case the CAF number & login ID do not belong to you, kindly contact our helpdesk at 1800-180-2132 to proceed further with the registration process.');
            return;
        }
        alert('CAF Not Saved');
//        AlertService.error("Details Mached With Existing Caf.");
        vm.isSaving = false;
    }

    function uploadsign(projectid)
    {
       if(angular.isDefined(vm.cAFAttachmentSign.investorsign)){
           vm.cAFAttachmentSignobject.docName = "Signature";
           vm.cAFAttachmentSignobject.projectid = projectid;
           CAFAttachment.save(vm.cAFAttachmentSignobject,onSaveCAFAttachmentSignSuccess,onSaveCAFAttachmentSignError);
       }
       function onSaveCAFAttachmentSignSuccess (result) {
           vm.cafattachsignresult = result;
           var cafattachmentid = result.id;

           var filename = result.id;
           var file = vm.cAFAttachmentSign.investorsign;
           FileManagement.saveFile(file,filename,onSignFileSaveSuccess,onSignFileSaveError(cafattachmentid));
           vm.isSaving = false;
       }

       function onSignFileSaveSuccess(result){
           $scope.$emit('investhryApp:cAFAttachmentUpdate', vm.cafattachsignresult);
           var CAFAttachmentResultObject = vm.cafattachsignresult;
           var filename = CAFAttachmentResultObject.id;
           var file = vm.cAFAttachmentSign.investorsign;
           var fileNameArray = file.name.split(".");
           var fileextension="";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName = CAFAttachmentResultObject.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           CAFAttachmentResultObject.fileName=inputFileName;
           CAFAttachment.update(CAFAttachmentResultObject, onUpdateCAFAttachmentSignSuccess, onUpdateCAFAttachmentSignError);
       }
       function onSignFileSaveError(cafattachmentid)
       {
           CAFAttachment.delete({id:cafattachmentid});
       }
       function onSaveCAFAttachmentSignError()
       {
           vm.isSaving = false;
       }

       function onUpdateCAFAttachmentSignSuccess(result)
       {
           vm.isSaving = false;
       }
       function onUpdateCAFAttachmentSignError()
       {
           vm.isSaving = false;
       }
    }



    function uploadphoto(projectid)
    {
       if(angular.isDefined(vm.cAFAttachmentPhoto.investorphoto)){
           vm.cAFAttachmentPhotoobject.docName = "Applicant Photo";
           vm.cAFAttachmentPhotoobject.projectid = projectid;
           CAFAttachment.save(vm.cAFAttachmentPhotoobject,onSaveCAFAttachmentPhotoSuccess,onSaveCAFAttachmentPhotoError);
       }
       function onSaveCAFAttachmentPhotoSuccess (result) {
           vm.cafattachphotoresult = result;
           var cafattachmentid = result.id;

           var filename = result.id;
           var file = vm.cAFAttachmentPhoto.investorphoto;
           FileManagement.saveFile(file,filename,onPhotoFileSaveSuccess,onPhotoFileSaveError(cafattachmentid));
           vm.isSaving = false;
       }

       function onPhotoFileSaveSuccess(result){
           $scope.$emit('investhryApp:cAFAttachmentUpdate', vm.cafattachphotoresult);
           var CAFAttachmentResultObject=vm.cafattachphotoresult;
           var filename = CAFAttachmentResultObject.id;
           var file = vm.cAFAttachmentPhoto.investorphoto;
           var fileNameArray = file.name.split(".");
           var fileextension = "";
           if((fileNameArray.length>1))
           {
               fileextension=fileNameArray[(fileNameArray.length-1)];
           }
           var inputFileName = CAFAttachmentResultObject.id;
           if(fileextension!=="")
           {
               inputFileName=inputFileName+"."+fileextension;
           }
           CAFAttachmentResultObject.fileName=inputFileName;
           CAFAttachment.update(CAFAttachmentResultObject);
       }

       function onPhotoFileSaveError(cafattachmentid)
       {
           CAFAttachment.delete({id:cafattachmentid});
       }

       function onSaveCAFAttachmentPhotoError()
       {
           vm.isSaving = false;
       }

    }

    for(var attachmentcounter=0;attachmentcounter<vm.cafAttachments.length;attachmentcounter++)
    {
        if(vm.cafAttachments[attachmentcounter].docName=="Applicant Photo")
        {
            vm.investorphoto=vm.cafAttachments[attachmentcounter];
        }
        if(vm.cafAttachments[attachmentcounter].docName=="Signature")
        {
            vm.investorsign=vm.cafAttachments[attachmentcounter];
        }
    }

     function onUpdateProjectSuccess(result)
     {
        replaceUploadedPhoto(result);
        replaceUploadedSign(result);
        alert('CAF Edited');
         $state.go('listproject');
         vm.isSaving = false;
     }
     function onUpdateProjectError(result){
         alert('CAF Not Edited');
         vm.isSaving = false;
     }

     function replaceUploadedPhoto(result)
     {
         if(angular.isDefined(vm.cAFAttachmentPhoto.investorphoto)){
            if(vm.investorphoto!==null){
              var file=vm.cAFAttachmentPhoto.investorphoto;
              var filename=vm.investorphoto.id;
              FileManagement.saveFile(file,filename,onreplaceUploadedPhotoSuccess,onreplaceUploadedPhotoError);
            }
            else
            {
              uploadphoto(result.id);
            }
         }
         function onreplaceUploadedPhotoSuccess(){
               var file=vm.cAFAttachmentPhoto.investorphoto;
               var filename=vm.investorphoto.id;
               var fileNameArray = file.name.split(".");
               var fileextension="";
               if((fileNameArray.length>1))
               {
                   fileextension=fileNameArray[(fileNameArray.length-1)];
               }

               var inputFileName=vm.investorphoto.id;
               if(fileextension!=="")
               {
                 inputFileName=inputFileName+"."+fileextension;
               }
               vm.investorphoto.fileName=inputFileName;
               CAFAttachment.update(vm.investorphoto,onUpdateCAFAttachmentPhotoSuccess,onUpdateCAFAttachmentPhotoError);
               vm.isSaving = false;
         }
         function onreplaceUploadedPhotoError(){
               vm.isSaving=false;
         }
         function onUpdateCAFAttachmentPhotoSuccess(){
               vm.isSaving=false;
         }
         function onUpdateCAFAttachmentPhotoError(){
               vm.isSaving=false;
         }
     }

     function replaceUploadedSign(result)
     {
          if(angular.isDefined(vm.cAFAttachmentSign.investorsign)){
             if(vm.investorsign!==null){
               var file = vm.cAFAttachmentSign.investorsign;
               var filename = vm.investorsign.id;
               FileManagement.saveFile(file,filename,onreplaceUploadedSignSuccess,onreplaceUploadedSignError);
             }
             else
             {
               uploadsign(result.id);
             }
          }
          function onreplaceUploadedSignSuccess(){
                var file = vm.cAFAttachmentSign.investorsign;
                var filename = vm.investorsign.id;
                var fileNameArray = file.name.split(".");
                var fileextension="";
                if((fileNameArray.length>1))
                {
                    fileextension=fileNameArray[(fileNameArray.length-1)];
                }

                var inputFileName=vm.investorsign.id;
                if(fileextension!=="")
                {
                    inputFileName=inputFileName+"."+fileextension;
                }
                vm.investorsign.fileName=inputFileName;
                CAFAttachment.update(vm.investorsign,onUpdateCAFAttachmentSignSuccess,onUpdateCAFAttachmentSignError);
                vm.isSaving = false;
          }
          function onreplaceUploadedSignError(){
                vm.isSaving=false;
          }
          function onUpdateCAFAttachmentSignSuccess(){
                vm.isSaving=false;
          }
          function onUpdateCAFAttachmentSignError(){
                vm.isSaving=false;
          }
     }
loadAllCountries();
 function loadAllCountries() {
     vm.allcountries=CountryService.getAllCountries();
 }

        vm.confirmProject = function () {
             if(vm.account.authorities.includes('ROLE_USER')){
                 vm.sendOTP(vm.proj.mobilenumber);
             }
            $('#confirmCaf').modal('show');
        };
        vm.editForm = function() {
            $('#confirmCaf').modal('hide');
            vm.OTP = false;
            vm.cafOTP = null;
        };
        vm.saveAndClose = function () {
            $('#confirmCaf').modal('hide');
            vm.OTP = false;
            vm.cafOTP = null;
            $timeout(function() {
                saveProject('Final')
            }, 1000);
        };
        function saveCAF() {

            if(vm.isIndustrialEstate==="no" || vm.isIndustrialEstate===""){
                vm.proj.industrialEstate=null;
            }

            if (vm.proj.id !== null)
            {
                AddCAF.update(vm.proj,onUpdateProjectSuccess,onUpdateProjectError);
            }
            else
            {
                AddCAF.save(vm.proj,onSaveProjectSuccess,onSaveProjectError);
            }
        }

        vm.sendOTP = function (mobilenumber) {
            CAFOtp.get({mobileNumber:mobilenumber},function (result) {
                if(result.sending ===true) {
                    vm.OTPstatus = true;
                    vm.cafOTP= null;
                }
                else {
                    vm.OTPstatus = false;
                }
            })

        };

        //Inside your AngularJS controller
        vm.verifyPan = function() {
            var inputData = {
                pan: vm.proj.pan_number,
                name: vm.proj.businessentity,
                fathername: '',
                dob:'01/01/2015'
            };

            AddCAF.verifyPan(inputData, function(response) {
                console.log('Raw API Response:', response);
                // Extract only the numbered properties from the response manually
                var message = '';
                for (var key in response) {
                    if (response.hasOwnProperty(key) && !isNaN(key)) {
                        message += response[key]; // Append only numeric keys
                    }
                }
                console.log('Extracted Message:', message);
                if (message.indexOf('verified') !== -1) {
                    vm.panSuccessMessage = message; // Display success response
                    vm.panErrorMessage = ''; // Clear error message
                } else {
                    vm.panErrorMessage = message; // Display error response
                    vm.panSuccessMessage = ''; // Clear success message
                }
            }, function(error) {
                vm.panErrorMessage = 'An error occurred while verifying PAN. Please try again.';
                console.error('Error from verifyPan API:', error);
            });
        };


        vm.verifyOTP = function(mobileNumber,OTP){
                CAFOtp.get({mobileNumber:mobileNumber,OTP:OTP},function (result) {
                    if(result.verified ===true) {
                        vm.OTP = true;
                        vm.cafOTP = null;
                        CAFOtp.get({mobileNumber: mobileNumber, status: true, user: '1'}, function (result) {

                        })
                    }
                else {
                    alert("WRONG OTP")
                }

            })
            };

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});
    }

})();
