(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('iServiceFormController', iServiceFormController);

    iServiceFormController.$inject = ['$timeout', '$scope','Principal', '$stateParams', '$sce','Project','ExtendedUserInformation','Users','ExtendedUsers','Projectservicedetail'];

     function iServiceFormController($timeout, $scope,Principal, $stateParams, $sce, Project, ExtendedUserInformation,Users,ExtendedUsers,Projectservicedetail) {

        var vm = this;
        vm.urlsource = '';
        vm.methodType = 'GET';
        vm.projectid = $stateParams.projectid;
        vm.requestType = $stateParams.requestType;
        vm.uname = Users.login;//login
        vm.useremail = Users.email;
        vm.address=Project.address1+' '+Project.address2+' '+Project.address3;//investor address
        vm.mobile=ExtendedUsers.userMobile;
        vm.serviceid = $stateParams.serviceid;
        vm.projectserviceid = $stateParams.projectserviceid;
        vm.city = Project.cityname;
        vm.investorname = Project.firstname;
        vm.state = Project.statename;
        vm.country = Project.countryname;
        vm.niccode = Project.niccode;
        vm.cafType = Project.caftype;
        vm.projecttype = Project.projecttype;
        vm.pannumber = Project.pan_number;
        vm.gstnumber = Project.gstnumber;
        vm.projecttype = Project.projectype;
        vm.aadhar_number = Project.aadhar_number;
        vm.proposedbuilt_up_area = Project.proposedbuilt_up_area;
        vm.totalproposedprojectarea = Project.totalproposedprojectarea;
        vm.totalpurposedemployment = Project.totalpurposedemployment;
        vm.total_project_cost = Project.total_project_cost;
        vm.district = Project.district;
        vm.landzoneuse_type = Project.landzoneuse_type;
        vm.businessentity = Project.businessentity;
        vm.cafpin = Project.cafpin;
        vm.projectlevel = Project.projectlevel;
        vm.projectservicedetail=Projectservicedetail;

         if (Project.lastname!=null){
                    vm.investorname = Project.firstname+' '+Project.lastname;

                } else {
                     vm.investorname = Project.firstname;
       }
         if(vm.projectservicedetail.departmentName === 'EXCISE & TAXATION - EXCISE'){
             vm.methodType = 'POST';
         }

        vm.actionURL = $sce.trustAsResourceUrl(vm.projectservicedetail.url);
        alert("Redirecting to the URL: " + vm.projectservicedetail.url);

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            angular.element(document.getElementById('fillform')).trigger('click')

        });

    }
})();
