(function() {
    'use strict';

    angular
        .module('investhryApp')
        .factory('translationHandler', translationHandler);

    translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate','translationStorageProvider'];

    function translationHandler($rootScope, $window, $state, $translate,translationStorageProvider) {
        return {
            initialize: initialize,
            updateTitle: updateTitle,
            changeLanguage: changeLanguage // New method to switch languages
        };

        function initialize() {
            // Set the default or saved language
            var savedLanguage = translationStorageProvider.get('NG_TRANSLATE_LANG_KEY') || 'en';
            $translate.use(savedLanguage);

            // Update the title when the language changes
            var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function() {
                updateTitle();
            });

            // Clean up listener on destroy
            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                    translateChangeSuccess();
                }
            });
        }

        function updateTitle(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        }

        // Dynamically change the language and save the preference
        function changeLanguage(langKey) {
            $translate.use(langKey);
            translationStorageProvider.set('NG_TRANSLATE_LANG_KEY', langKey); // Save the language preference
        }
    }
})();
